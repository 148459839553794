.dropdown-item:hover {
    background-color: transparent;
    color: #211E6D;
}

.side {
    background-color: #211E6D;
    color: white;
    border: 0px solid transparent;
    border-radius: 50%;
    padding: 0.2em;
    font-size: 2em;
}

.offcanvas {
    margin-top: 66px;
}

.offcanvas-body>ul>li>a>i {
    color: white;
}

.nav-link {
    position: relative;
    font-weight: 400;
    color: #000;
    /* text-transform: uppercase; */
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
    color: #211E6D;
    font-weight: 400;

}

nav ul li a.menu:hover::before,
nav ul li a.menu:focus::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -14px;
    width: 100%;
    height: 10%;
    background-color: #17a2d0;
    color: #211E6D;
}

.dropdown-item:active {
    color: #211E6D;
}

.offcanvas-body ul {
    padding-left: 0;
    list-style: none;
}

.offcanvas-body ul li {
    padding: 10px 0;
}

.offcanvas-body ul li a {
    text-decoration: none;
    color: #fff;
    transition: all .3s;
}

.offcanvas-body ul li a:hover {
    padding-left: 5px;

}

.contact-link i {
    text-decoration: none;
    color: #fff;
    font-size: 24px;
}

.email-dwellfox {
    text-decoration: none;
    color: #fff;
}

.email-dwellfox:hover {
    color: #fff;
}

.home-heading {
    font-size: 64px;
    font-weight: 100;
}

.dropdown-toggle::after {
    display: none;

}


/* .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
    border: none;

    
}
.btn:hover{
    border: none;
} */
.offcanvasmobil{
    display: none;
}
.offcanvasmobil .offcanvas-body::-webkit-scrollbar{
    display: none;
}
.offcanvasmobil .offcanvas {
    margin-top: 0;
}
@media screen and (max-width:1144px){
    #navbarSupportedContent ul{
        display: none;
    }
}

@media screen and (max-width:992px) {
    .ind-and-side {
        display: none !important;
    }
    .megamenu{
        background: #fff;
    }
    .dropdown-menu:after{
        display: none;
    }
    .industrimega{
        color: #000!important;
    }
    h6{
        font-size: 14px;
    }

    .offcanvas-body ul li a{

        color: #000;

    }
    .nav-link3 {
        padding: 10px !important;
        border-bottom: 1px solid #cdcbcb!important;
        
    }
    .borderbotonnone{
        border-bottom: 0!important;
    }
    .leftborder{
        border-left: 5px solid #211E6D;
    }
    /* .offcanvas-header{
        justify-content: end;
    } */
    .menuicon{
        font-size: 1rem !important;
    }
    
    .offcanvas-body ul li{
        border-bottom: 1px solid #ddd;
    }
    .offcanvasmobil{
        display: block;
    }

    .header_slider {
        display: none !important;
    }
 
    .mobileclose{
        display: block;
    }
    nav ul li a.menu:hover::before, nav ul li a.menu:focus::before{
        display: none;
    }

    .navbar.fixed-top .navbar-collapse,
    .navbar.sticky-top .navbar-collapse {
        overflow-y: auto;
        max-height: 90vh;
        margin-top: 10px;

    }
   
        
    .navbar.fixed-top .navbar-collapse::-webkit-scrollbar{
        display: none;
    }
    .has-megamenu  svg {
        float: right;
        font-size: 1.5rem;
    }
    #allservices svg{
        float: right;
        font-size: 1.5rem;

    }
    

}

/* 
MEGA MENU */


.dropdown-menu[data-bs-popper] {
    margin: 0;
}

/* .show {
    width: 100%;
} */

.ind-and-side ul.show {

    width: auto;
}

.ind-and-side .dropdown-menu[data-bs-popper] {
    position: absolute;
    left: -142px;
    top: 62px;
}

.ind-and-side .dropdown-item {
    padding: 10px;
}

.ind-and-side .dropdown-calling-item {
    padding: 3px;
    text-decoration: none;
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.megamenu {
    border: none;
    margin-top: 0;
    border-radius: 0;
    background: linear-gradient(rgba(225, 231, 240, 1) 0%, rgba(249, 250, 251, 1) 100%);
}

.nav-link3 {
    width: 100%;
    display: block;
    border: none;
    padding: 5px;
    text-align: left;
    color: #211E6D;
    font-size: 14px;
    font-weight: 800;
    background-color: transparent;
    transition: all .1s linear;

}

.megamenu button.active {
    background-color: #17a2d0;
    color: white;

}

.nav-link3:focus {
    background-color: #17a2d0;
    color: white;
    font-weight: bolder;
}

.nav-link3:hover {
    background-color: #17a2d0;
    color: white;
    font-weight: 600;
    border-radius: 4px;
}

.menuicon {
    text-decoration: none;
    color: black;
    font-size: 13px;
    font-weight: 400;
}

.ind-button ul {
    border: none;
    background: linear-gradient(90deg, rgba(225, 231, 240, 1) 0%, rgba(249, 250, 251, 1) 100%);
}


.countryimg {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.countryimg1 {
    width: 65%;
    height: 65%;
    /* border-radius: 5%; */
}


.dropdown-item:hover {
    background-color: #17a2d0;
    color: #fff;
}

.navbar .megamenu {
    padding: 1rem;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {

    .navbar .has-megamenu {
        position: static !important;
    }

    .navbar .megamenu {
        left: 0;
        right: 0;
        width: 100%;
        margin-top: 0;
    }

}

/* ============ desktop view .end// ============ */


/* ============ mobile view ============ */


.SV {
    text-decoration: none;
    color: #000;
}

/* @media(max-width: 576px){
	.megamenu_item{
		margin-top: 1100px
	}
} */


/* 14/12/22 pravin kushwah */
.industrimega {
    text-transform: uppercase;
    color: rgb(33 30 109);

}

.colr {
    color: rgb(96, 105, 122);
}

.nav-link3:hover .industrimega {
    color: white;
    font-weight: 600;

}

.nav-link3:hover .colr {
    color: white;


}

.megasliderimg {
    height: 100vh;
    object-fit: cover;
}


.navbar-toggler:focus{
    outline: none;
    text-decoration: none;
    box-shadow: none;
}

.Cont-info-inquiry {
    width: 100%;
    display: inline-block;
    padding: 0;
    background: #fff;
    border-radius: 5px;
    margin-top: 0;
    position: relative;
    box-shadow: 0 0 5px rgb(0 0 0 / 30%)
}

.Cont-info-inquiry:after, .top-info .single-item:before {
    content: '';
}
/* .videosection{
    object-fit:fill;
} */
.Cont-info-inquiry:after {
    position: absolute;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid transparent;
    border-bottom: 7px solid rgba(255,255,255,.95);
    top: -13px;
    right: 22px;
    transition: all 1s ease;
    -moz-transition: all 1s ease;
    -webkit-transition: all 1s ease;
}

.dropdown-menu:after, .top-info .single-item:before {
    content: '';
}

.dropdown-menu:after {
    position: absolute;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid transparent;
    border-bottom: 7px solid rgba(255,255,255,.95);
    top: -13px;
    right: 22px;
    transition: all 1s ease;
    -moz-transition: all 1s ease;
    -webkit-transition: all 1s ease;
}

.Cont-info-inquiry .con-heading {
    font-size: 19px;
    color: #3c4147;
    padding: 11px 20px;
    letter-spacing: .2px;
    font-weight: 600;
}

.Cont-info-inquiry .inquiry-block {
    border-top: 1px solid #e1e1e2;
    position: relative;
    min-height: 110px;
    text-align: left;
    padding: 15px 10px 15px 108px;
}

.Cont-info-inquiry .inquiry-block .img.sales-inq {
    background-position: -3px 0;
}

.Cont-info-inquiry .inquiry-block .img.hr-inq {
    background-position: -110px 0;
}

.Cont-info-inquiry .inquiry-block .img {
    /* background: url('../../../public/Assets/Group62476.webp') no-repeat; */
    width: 80px;
    height: 95px;
    position: absolute;
    left: 20px;
    top: 50%;
    margin-top: -47px;
}

.Cont-info-inquiry .inquiry-block .inqSal {
    margin: 0;
    font-size: 16px;
    color: rgba(0,0,0,.8);
    font-weight: 600;
}

.Cont-info-inquiry .inquiry-block ul {
    margin: 4px 0 2px;
    display: inline-block;
    width: 100%;
    padding-left: 0;
}

.Cont-info-inquiry .inquiry-block ul li {
    list-style: none;
    /* padding-left: 42px; */
    margin: 3px 0;
    text-align: left;
}

.Cont-info-inquiry .inquiry-block ul li {
    position: relative;
    background-image: inherit;
}

.Cont-info-inquiry .inquiry-block span {
    font-size: 13px;
    color: #828282;
}

.dropdown-menu-calling {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
}

.ind-and-side .dropdown-menu .dropdown-menu-calling [data-bs-popper] {
    position: absolute;
    left: -300px;
    top: 57px;
}

.dropdown-menu-calling[data-bs-popper] {
    margin: 0;
}

.offcanvas.offcanvas-end{
    margin-top: 69px;
}


/* pravin kushwah */
.industries-megamenu-overflow{
    height: 95vh;
    overflow-y: auto;
   
}
.industries-megamenu-overflow::-webkit-scrollbar{
    display: none;
}

.kushwah{
    display: none;

}
.kushwah a{
    display: block;
    width: 100%;
    transition: all .3s linear;
    font-size: 14px!important;
}

/* testing */
.pravin:hover .kushwah{
    display: block;
    transition: all .5s linear;

}

.showall_services{
    display: none;
}

#allservices:hover .showall_services {
display: block;
}

.navbar-toggler-icon{
    background-image: url('/public/Assets/Header/menu.png')!important;
}

